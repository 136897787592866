var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center justify-content-around pb-5",staticStyle:{"height":"100%","position":"relative"}},[_c('splide',{attrs:{"slides":_vm.data,"options":_vm.options},on:{"splide:move":_vm.onMove,"splide:active":_vm.onMoved}},_vm._l((_vm.data),function(item,index){return _c('splide-slide',{key:item.id},[_c('div',{staticClass:"image"},[_c('div',{staticClass:"top show"},[_c('h3',{staticClass:"bold yellow mb-0",class:{
                                'animate__animated animate__fadeIn': _vm.isActive,
                                'animate__animated animate__fadeIn animate__delay-2s': _vm.isStart
                            }},[_vm._v(" "+_vm._s(index + 1 > 9 ? "" + (index + 1) : "0" + (index + 1))+" ")]),_c('h3',{staticClass:"title",class:{
                                'animate__animated animate__fadeIn animate__delay-1': _vm.isActive,
                                'animate__animated animate__fadeIn animate__delay-2s': _vm.isStart
                            }},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"container sub show"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h6',{staticClass:"cat",class:{
                                        'animate__animated animate__fadeIn animate__delay-1s': _vm.isActive,
                                        'animate__animated animate__fadeIn animate__delay-3s': _vm.isStart
                                    }},[_vm._v(" "+_vm._s(item.category)+" ")]),_c('p',{staticClass:"white",class:{
                                        'animate__animated animate__fadeIn animate__delay-2s': _vm.isActive,
                                        'animate__animated animate__fadeIn animate__delay-4s': _vm.isStart
                                    }},[_vm._v(" "+_vm._s(item.description)+" ")])])]),_c('div',{staticClass:"row mt-3",class:{
                                'animate__animated animate__fadeIn animate__delay-3s': _vm.isActive,
                                'animate__animated animate__fadeIn animate__delay-5s': _vm.isStart
                            }},[_c('div',{staticClass:"col-12 mb-3"},[_c('span',{staticClass:"white"},[_vm._v("Built with :")])]),_vm._l((item.stacks),function(stack){return _c('div',{key:stack.id},[_c('div',{staticClass:"col-1 mb-3"},[_c('img',{attrs:{"src":stack.image}})])])})],2),_c('div',{staticClass:"row mt-3",class:{
                                'animate__animated animate__fadeIn animate__delay-3s': _vm.isActive,
                                'animate__animated animate__fadeIn animate__delay-5s': _vm.isStart
                            }},[_c('div',{staticClass:"col-12 mb-3 view-image"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":item.image,"target":"_blank"}},[_vm._v("View Image")])])])]),_c('div',{staticClass:"bg-group"},[_c('img',{staticClass:"bg-show",attrs:{"src":item.image}})])])])}),1),_c('h6',{staticClass:"py-3"},[_vm._v("Showcase - Selected Works")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }