<template>
    <div class="p-1">
        <a href="#" @click.prevent class="navbar-brand mb-0">
            <div class="text-center">
                <div v-bind:class="[!hover ? 'shake yellow' : '']">
                    <svg
                        id="logo"
                        height="60"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 65 91"
                    >
                        <defs>
                            <clipPath id="theClipPath">
                                <rect
                                    class="masker"
                                    x="0"
                                    y="0"
                                    width="65"
                                    height="91"
                                />
                            </clipPath>
                        </defs>
                        <path
                            fill="#303030"
                            id="logo-fill"
                            d="M32.5056 38.4352L17.3717 41.4063L23.6875 35.822L64.0095 6.0801L35.6516 33.1962L57.5098 26.0837L34.3952 44.4683L52.469 40.6604L41.5245 52.017L1.08926e-06 84.8855L16.6852 67.9968L30.8646 53.1578L5.32635 57.5312L32.5056 38.4352Z"
                        />
                        <g id="clipPathReveal" clip-path="url(#theClipPath)">
                            <path
                                fill="#ffd600"
                                d="M32.5056 38.4352L17.3717 41.4063L23.6875 35.822L64.0095 6.0801L35.6516 33.1962L57.5098 26.0837L34.3952 44.4683L52.469 40.6604L41.5245 52.017L1.08926e-06 84.8855L16.6852 67.9968L30.8646 53.1578L5.32635 57.5312L32.5056 38.4352Z"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { TimelineMax } from "gsap";

export default {
    data() {
        return {
            hover: false,
            show: true,
        };
    },
    mounted() {
        var tl = new TimelineMax({ repeat: -1, repeatDelay: 3 });
        tl.set(".masker", { scaleY: 0, transformOrigin: "top center" })
            .to(".masker", 0.5, { scaleY: 1 })
            .to(".masker", 0.5, {
                scaleY: 0,
                transformOrigin: "bottom center",
            });
    },
};
</script>

<style scoped>
.logo-full {
    color: #303030;
    font-size: 12px;
    line-height: 12px;
}
</style>
