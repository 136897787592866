<template>
    <div class="container-fluid">
        <div
            class="row align-items-center justify-content-around pb-5"
            style="height: 100%; position: relative"
        >
            <splide
                :slides="data"
                @splide:move="onMove"
                @splide:active="onMoved"
                :options="options"
            >
                <splide-slide v-for="(item, index) in data" :key="item.id">
                    <div class="image">
                        <div class="top show">
                            <h3
                                class="bold yellow mb-0"
                                :class="{
                                    'animate__animated animate__fadeIn': isActive,
                                    'animate__animated animate__fadeIn animate__delay-2s': isStart
                                }"
                            >
                                {{
                                    index + 1 > 9
                                        ? "" + (index + 1)
                                        : "0" + (index + 1)
                                }}
                            </h3>
                            <h3
                                class="title"
                                :class="{
                                    'animate__animated animate__fadeIn animate__delay-1': isActive,
                                    'animate__animated animate__fadeIn animate__delay-2s': isStart
                                }"
                            >
                                {{ item.name }}
                            </h3>
                        </div>
                        <div class="container sub show">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6
                                        class="cat"
                                        :class="{
                                            'animate__animated animate__fadeIn animate__delay-1s': isActive,
                                            'animate__animated animate__fadeIn animate__delay-3s': isStart
                                        }"
                                    >
                                        {{ item.category }}
                                    </h6>
                                    <p
                                        class="white"
                                        :class="{
                                            'animate__animated animate__fadeIn animate__delay-2s': isActive,
                                            'animate__animated animate__fadeIn animate__delay-4s': isStart
                                        }"
                                    >
                                        {{ item.description }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row mt-3"
                                :class="{
                                    'animate__animated animate__fadeIn animate__delay-3s': isActive,
                                    'animate__animated animate__fadeIn animate__delay-5s': isStart
                                }"
                            >
                                <div class="col-12 mb-3">
                                    <span class="white">Built with :</span>
                                </div>
                                <div
                                    v-for="stack in item.stacks"
                                    :key="stack.id"
                                >
                                    <div class="col-1 mb-3">
                                        <img :src="stack.image" />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row mt-3"
                                :class="{
                                    'animate__animated animate__fadeIn animate__delay-3s': isActive,
                                    'animate__animated animate__fadeIn animate__delay-5s': isStart
                                }"
                            >
                                <div class="col-12 mb-3 view-image">
                                    <a
                                        :href="item.image"
                                        target="_blank"
                                        class="btn btn-primary"
                                        >View Image</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="bg-group">
                            <img class="bg-show" :src="item.image" />
                        </div>
                    </div>
                </splide-slide>
            </splide>
            <h6 class="py-3">Showcase - Selected Works</h6>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            options: {
                type: "slide",
                perPage: 1,
                height: "70vh",
                width: "100vw",
                fixedWidth: "80vw",
                gap: "5vw",
                padding: {
                    right: "5vw",
                    left: "10vw"
                }
            },
            isActive: false,
            isStart: true
        };
    },
    components: {
        Splide,
        SplideSlide
    },
    mounted() {
        this.getWorks();
    },
    methods: {
        ...mapActions({
            getData: "work/getData"
        }),
        async getWorks() {
            await this.getData();
        },
        onMove() {
            this.isActive = false;
            this.isStart = false;
        },
        onMoved() {
            this.isActive = true;
        }
    },
    computed: {
        ...mapGetters({
            data: "work/data"
        })
    }
};
</script>

<style scoped>
@import "~@splidejs/splide/dist/css/themes/splide-default.min.css";
.bg-group {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.bg-show {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
}

.show {
    display: none;
}
.is-active > .show {
    display: block;
}

.is-active > .image > .show {
    display: block;
}

.is-active > .image > .bg-group > .show {
    display: block;
}

.is-active > .image > .bg-group {
    height: 78%;
    animation: reduce 2s;
}
.is-active > .image {
    width: 100%;
}
.image > .bg-group {
    height: 100%;
    animation: normalize 1s;
}

.bold {
    font-weight: 900;
}
.bold {
    font-weight: 800;
}
.white {
    color: #fff;
}
.yellow {
    color: #ffd600;
}
.cat {
    color: #ffd600;
    font-weight: 900;
}

.image {
    position: relative;
    height: 100%;
    width: 100%;
}

.sub {
    position: absolute;
    width: 100%;
    padding: 50px 0 0 50px;
    z-index: 5;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(11, 18, 27, 0.7);
    z-index: 1;
}

@keyframes reduce {
    from {
        height: 100%;
    }
    to {
        height: 78%;
    }
}

@keyframes normalize {
    from {
        height: 78%;
    }
    to {
        height: 100%;
    }
}

@media (max-width: 575.98px) {
    .title {
        font-size: 20px;
    }
    .sub {
        padding: 10px 0 0 10px;
    }
    .is-active > .image > .bg-group {
        height: 90%;
        animation: reduce 2s;
    }

    @keyframes reduce {
        from {
            height: 100%;
        }
        to {
            height: 90%;
        }
    }

    @keyframes normalize {
        from {
            height: 90%;
        }
        to {
            height: 100%;
        }
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .view-image {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .view-image {
        display: none;
    }
}

@media (min-width: 1200px) {
    .view-image {
        display: none;
    }
}
</style>
