<template>
    <div class="container-fluid">
        <div class="row align-items-center" style="min-height: 100%">
            <div class="col-md-4 order-md-1 order-sm-2 order-2 pb-5">
                <Greeting></Greeting>
            </div>
            <div
                class="col-md-8 order-md-2 order-sm-1 order-1 animate__animated animate__fadeIn animate__delay-2s text-center"
            >
                <Coding></Coding>
            </div>
        </div>
    </div>
</template>
<script>
import Greeting from "../components/Greeting.vue";
import Coding from "../components/Coding.vue";

export default {
    components: {
        Greeting,
        Coding
    },
    mounted() {}
};
</script>
<style scoped>
#photo:hover {
    animation: scale 0.5s;
}

@keyframes scale {
    to {
        transform: scale(1.2);
    }
}
.background {
    position: absolute;
    bottom: 5vh;
    right: 5vh;
    z-index: -1;
}

.exit-leave-active {
    animation: fadeOutLeft 1s;
}
</style>
