<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~animate.css";
</style>
