<template>
    <div>
        <svg
            width="90%"
            viewBox="0 0 786 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="coding">
                <path
                    id="Vector 20"
                    d="M6.16746 315.627C-16.8821 377.296 32.0981 449.004 32.0981 449.004H700.533C700.533 449.004 752.486 427.338 769.681 398.808C830.186 298.416 703.414 272.601 670.28 228.142C637.147 183.683 582.404 71.818 429.701 76.1207C276.999 80.4234 246.746 175.078 170.395 195.157C94.0435 215.235 29.217 253.957 6.16746 315.627Z"
                    fill="#E7F3FF"
                />
                <g id="interface-1">
                    <rect
                        id="Rectangle 8"
                        x="386"
                        width="188"
                        height="98"
                        fill="#9FC9FB"
                    />
                    <circle
                        id="Ellipse 3"
                        cx="401.859"
                        cy="6.05522"
                        r="3.17178"
                        fill="white"
                    />
                    <circle
                        id="Ellipse 4"
                        cx="411.086"
                        cy="6.05522"
                        r="3.17178"
                        fill="white"
                    />
                    <circle
                        id="Ellipse 5"
                        cx="392.632"
                        cy="6.05522"
                        r="3.17178"
                        fill="white"
                    />
                    <rect
                        id="Rectangle 9"
                        x="389"
                        y="15"
                        width="182"
                        height="79"
                        rx="2"
                        fill="white"
                    />
                    <rect
                        id="Rectangle 10"
                        x="396"
                        y="18"
                        width="91"
                        height="7"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 12"
                        x="396"
                        y="36"
                        width="153"
                        height="5"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 11"
                        x="396"
                        y="44"
                        width="153"
                        height="5"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 13"
                        x="396"
                        y="55"
                        width="51"
                        height="4"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 14"
                        x="491"
                        y="76"
                        width="26"
                        height="7"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 15"
                        x="523"
                        y="76"
                        width="26"
                        height="7"
                        rx="2"
                        fill="#9BC9FD"
                    />
                </g>
                <g id="interface-2">
                    <rect
                        id="Rectangle 8_2"
                        x="386"
                        y="111"
                        width="188"
                        height="98"
                        fill="#9FC9FB"
                    />
                    <rect
                        id="Rectangle 9_2"
                        x="390"
                        y="116"
                        width="180"
                        height="88"
                        rx="1"
                        stroke="white"
                        stroke-width="2"
                    />
                    <circle
                        id="Ellipse 6"
                        cx="480.5"
                        cy="160.5"
                        r="29"
                        stroke="white"
                        stroke-width="3"
                    />
                    <path
                        id="Polygon 1"
                        d="M496 160.5L471.25 174.789L471.25 146.211L496 160.5Z"
                        fill="white"
                    />
                </g>
                <g id="interface-3">
                    <rect
                        id="Rectangle 8_3"
                        x="386"
                        y="222"
                        width="188"
                        height="88"
                        fill="#9FC9FB"
                    />
                    <circle
                        id="Ellipse 3_2"
                        cx="401.859"
                        cy="228.055"
                        r="3.17178"
                        fill="white"
                    />
                    <circle
                        id="Ellipse 4_2"
                        cx="411.086"
                        cy="228.055"
                        r="3.17178"
                        fill="white"
                    />
                    <circle
                        id="Ellipse 5_2"
                        cx="392.632"
                        cy="228.055"
                        r="3.17178"
                        fill="white"
                    />
                    <rect
                        id="Rectangle 9_3"
                        x="389"
                        y="237"
                        width="182"
                        height="68"
                        rx="2"
                        fill="white"
                    />
                    <rect
                        id="Rectangle 10_2"
                        x="399"
                        y="248"
                        width="44"
                        height="46"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 12_2"
                        x="453"
                        y="258"
                        width="96"
                        height="5"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 11_2"
                        x="453"
                        y="267"
                        width="96"
                        height="5"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 13_2"
                        x="453"
                        y="248"
                        width="51"
                        height="6"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 14_2"
                        x="453"
                        y="287"
                        width="26"
                        height="7"
                        rx="2"
                        fill="#9BC9FD"
                    />
                    <rect
                        id="Rectangle 15_2"
                        x="484"
                        y="287"
                        width="26"
                        height="7"
                        rx="2"
                        fill="#9BC9FD"
                    />
                </g>
                <g id="check-1">
                    <circle
                        id="Ellipse 7"
                        cx="599.5"
                        cy="54.5"
                        r="11.5"
                        fill="#9FD79C"
                    />
                    <path
                        id="Vector 30"
                        d="M597.696 58.057C597.09 58.057 593.451 54.2326 593.451 54.2326C593.451 54.2326 592.934 54.9029 592.845 55.4093C592.755 55.9157 592.689 55.552 595.27 58.057C597.393 60.1163 597.09 60.1163 597.696 60.1163C598.303 60.1163 606.186 50.1139 606.186 50.1139V48.3488C606.186 48.3488 598.303 58.057 597.696 58.057Z"
                        fill="white"
                    />
                </g>
                <g id="check-2">
                    <circle
                        id="Ellipse 7_2"
                        cx="599.5"
                        cy="160.5"
                        r="11.5"
                        fill="#9FD79C"
                    />
                    <path
                        id="Vector 30_2"
                        d="M597.696 164.057C597.09 164.057 593.451 160.233 593.451 160.233C593.451 160.233 592.934 160.903 592.845 161.409C592.755 161.916 592.689 161.552 595.27 164.057C597.393 166.116 597.09 166.116 597.696 166.116C598.303 166.116 606.186 156.114 606.186 156.114V154.349C606.186 154.349 598.303 164.057 597.696 164.057Z"
                        fill="white"
                    />
                </g>
                <g id="check-3">
                    <circle
                        id="Ellipse 7_3"
                        cx="599.5"
                        cy="257.5"
                        r="11.5"
                        fill="#9FD79C"
                    />
                    <path
                        id="Vector 30_3"
                        d="M597.696 261.057C597.09 261.057 593.451 257.233 593.451 257.233C593.451 257.233 592.934 257.903 592.845 258.409C592.755 258.916 592.689 258.552 595.27 261.057C597.393 263.116 597.09 263.116 597.696 263.116C598.303 263.116 606.186 253.114 606.186 253.114V251.349C606.186 251.349 598.303 261.057 597.696 261.057Z"
                        fill="white"
                    />
                </g>
                <g id="man">
                    <g id="body">
                        <path
                            id="Vector 12"
                            d="M156.5 441C141.544 430.851 163.611 397.572 163.611 397.572L204.751 330L218 388.608L197.779 418.257C197.779 418.257 171.827 451.401 156.5 441Z"
                            fill="#F5BD97"
                        />
                        <path
                            id="Vector 11"
                            d="M159.964 401.089C159.964 401.089 169.849 378.829 179.868 363.493C190.008 347.971 204.194 328.11 204.194 328.11C204.194 328.11 210.828 322.212 220.411 317.789C231.157 312.83 249.161 308.206 249.161 308.206C249.161 308.206 263.426 304.604 272.75 304.52C282.643 304.431 297.814 308.206 297.814 308.206L305.185 311.155C305.185 311.155 324.056 318.12 328.037 320.738C334.332 324.878 336.347 328.503 340.569 334.744C342.78 338.013 344.362 362.134 347.203 381.185C349.677 397.77 347.39 418.613 347.214 420.163C347.209 420.222 347.203 420.255 347.203 420.255C347.203 420.255 347.207 420.224 347.214 420.163C347.277 419.486 347.394 415.349 340.569 417.306C335.792 418.676 333.934 420.255 333.934 420.255L328.037 457.85C328.037 457.85 320.666 463.01 272.75 457.85C224.834 452.69 207.143 457.85 207.143 457.85V409.935L196.822 424.678C196.822 424.678 193.762 407.903 186.502 402.563C179.121 397.134 159.964 401.089 159.964 401.089Z"
                            fill="#4C5099"
                        />
                        <path
                            id="Vector 10"
                            d="M258.007 305.257L249.898 308.943C249.898 308.943 252.109 322.949 276.436 322.949C296.339 322.949 297.076 308.943 297.076 308.943L288.968 305.995V290.514L258.007 286.828V305.257Z"
                            fill="#F5BD97"
                        />
                        <path
                            id="Vector 9"
                            d="M258.007 303.046V289.777L288.968 293.463V309.68C288.968 309.68 279.533 310.671 273.487 308.943C267.442 307.216 258.007 303.046 258.007 303.046Z"
                            fill="#F0A87F"
                        />
                        <g id="hand">
                            <path
                                id="Vector 15"
                                d="M159.138 418.622L210.336 420.224C210.336 420.224 248.404 422.721 274.144 424.567L281.322 436.984L257.907 443.533C257.907 443.533 165.99 456.433 155 440.5C144.01 424.567 159.138 418.622 159.138 418.622Z"
                                fill="#F5BD97"
                            />
                            <path
                                id="Vector 31"
                                d="M307.494 421.089C306.016 420.073 302.945 420.151 302.945 420.151C302.945 420.151 297.937 419.811 294.756 420.151C290.384 420.617 288.162 422.158 283.837 422.967C281.365 423.429 279.974 423.722 277.468 423.905C272.493 424.268 262 423.905 262 423.905L262 431.414L263.82 437.984C263.82 437.984 265.943 439.548 269.279 439.861C272.615 440.174 279.168 439.861 280.198 439.861C281.108 439.861 287.477 438.922 287.477 438.922C287.477 438.922 289.781 438.607 291.116 437.984C292.735 437.228 294.756 435.168 294.756 435.168L297.486 437.984C297.486 437.984 298.336 439.588 299.305 439.861C300.389 440.166 301.379 439.863 302.035 438.922C302.89 437.696 301.125 435.168 301.125 435.168L297.486 430.475C297.486 430.475 299.805 430.869 301.125 431.414C302.525 431.991 302.789 433.13 303.855 434.23C305.276 435.696 305.811 436.851 307.494 437.984C309.097 439.062 310.171 440.268 312.044 439.861C313.297 439.589 314.225 439.178 314.773 437.984C315.23 436.99 314.872 436.263 314.773 435.168C314.493 432.05 313.374 430.474 312.044 427.66C310.574 424.551 310.224 422.967 307.494 421.089Z"
                                fill="#F5BD97"
                            />
                        </g>
                    </g>
                    <g id="head">
                        <path
                            id="Vector 2"
                            d="M280.796 305.908C319.491 315.044 308.74 210.509 308.74 210.509L246.882 207.701L241.397 279.769C241.397 279.769 242.101 296.773 280.796 305.908Z"
                            fill="#F5BD97"
                        />
                        <path
                            id="Vector 3"
                            d="M243.873 257.743L241.364 280.505L230.885 251.25C230.885 251.25 219.235 231.536 220.573 218.314C221.911 205.092 230.628 205.807 235.568 196.857C238.874 190.866 235.972 186.541 241.391 182.363C251.332 174.697 262.393 179.518 274.797 177.975C283.817 176.854 290.585 170.484 297.759 176.066C302.95 180.106 300.147 186.606 304.116 191.851C308.259 197.327 314.961 196.287 317.208 202.776C319.682 209.916 313.628 216.634 313.628 216.634L309.68 254.827L307.1 214.124C307.1 214.124 287.82 216.201 281.929 215.933C276.038 215.666 257.025 211.851 257.025 211.851C257.025 211.851 252.975 219.784 250.498 225.575C249.26 228.471 252.189 239.018 249.529 246.931C248.011 251.448 243.873 257.743 243.873 257.743Z"
                            fill="#30343F"
                        />
                        <path
                            id="Vector 4"
                            d="M236.776 251.518C240.842 251.056 244.576 258.513 244.576 258.513L244.041 270.296C244.041 270.296 240.292 271.601 236.677 269.961C235.995 269.652 230.345 263.157 230.584 257.878C230.852 251.987 233.332 251.909 236.776 251.518Z"
                            fill="#F6BE98"
                        />
                        <path
                            id="Vector 5"
                            d="M276.24 243.716C276.256 242.495 274.131 241.407 274.131 241.407C274.131 241.407 269.82 240.473 266.801 240.336C263.781 240.199 259.403 240.738 259.403 240.738C259.403 240.738 257.844 241.843 257.83 242.88C257.814 244.102 259.203 245.157 259.203 245.157L274.667 245.858C274.667 245.858 276.227 244.754 276.24 243.716Z"
                            fill="#30343F"
                        />
                        <path
                            id="Vector 6"
                            d="M305.696 245.053C305.712 243.832 303.587 242.744 303.587 242.744C303.587 242.744 299.276 241.81 296.257 241.673C293.237 241.536 288.859 242.075 288.859 242.075C288.859 242.075 287.3 243.18 287.286 244.217C287.27 245.439 288.659 246.493 288.659 246.493L304.123 247.195C304.123 247.195 305.683 246.091 305.696 245.053Z"
                            fill="#30343F"
                        />
                        <path
                            id="Vector 7"
                            d="M281.028 268.285C279.48 268.215 277.413 266.645 277.413 266.645C277.413 266.645 275.735 266.567 275.171 267.281C274.435 268.212 275.773 270.26 275.773 270.26C275.773 270.26 278.956 273.44 281.531 273.473C284.246 273.508 287.589 270.058 287.589 270.058C287.589 270.058 288.1 268.609 287.689 267.849C287.126 266.805 284.777 266.979 284.777 266.979C284.777 266.979 282.577 268.355 281.028 268.285Z"
                            fill="#F0A57D"
                        />
                        <g id="eye">
                            <g id="round">
                                <g id="Ellipse 1">
                                    <circle
                                        cx="266.366"
                                        cy="249.909"
                                        r="2.94865"
                                        transform="rotate(2.59888 266.366 249.909)"
                                        fill="#282626"
                                    />
                                    <circle
                                        cx="266.366"
                                        cy="249.909"
                                        r="2.94865"
                                        transform="rotate(2.59888 266.366 249.909)"
                                        fill="#282626"
                                    />
                                </g>
                                <g id="Ellipse 2">
                                    <circle
                                        cx="297.295"
                                        cy="251.313"
                                        r="2.94865"
                                        transform="rotate(2.59888 297.295 251.313)"
                                        fill="#282626"
                                    />
                                    <circle
                                        cx="297.295"
                                        cy="251.313"
                                        r="2.94865"
                                        transform="rotate(2.59888 297.295 251.313)"
                                        fill="#282626"
                                    />
                                </g>
                            </g>
                            <g id="ushape">
                                <path
                                    id="Vector 26"
                                    d="M263.389 253.85C263.389 253.85 263.515 247.666 266.511 247.931C269.426 248.188 268.722 253.992 268.722 253.992"
                                    stroke="#30343F"
                                    stroke-width="2"
                                />
                                <path
                                    id="Vector 27"
                                    d="M294.357 255.256C294.357 255.256 294.481 249.072 297.619 249.34C300.673 249.601 299.943 255.404 299.943 255.404"
                                    stroke="#30343F"
                                    stroke-width="2"
                                />
                            </g>
                            <g id="stripe">
                                <path
                                    id="Vector 28"
                                    d="M261.994 250.241L270.985 250.649"
                                    stroke="#30343F"
                                />
                                <path
                                    id="Vector 29"
                                    d="M293.484 251.17L302.475 251.578"
                                    stroke="#30343F"
                                />
                            </g>
                        </g>
                        <g id="mouth">
                            <path
                                id="laugh"
                                d="M290.543 283.195L270.642 282.856C270.642 282.856 271.953 292.463 280.798 292.613C289.642 292.764 290.543 283.195 290.543 283.195Z"
                                fill="#FFFFFA"
                            />
                            <path
                                id="straight"
                                d="M276.867 286.453L291.897 286.134"
                                stroke="#30343F"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <circle
                                id="round_2"
                                cx="282.384"
                                cy="286.202"
                                r="4.5"
                                transform="rotate(2.59888 282.384 286.202)"
                                fill="#30343F"
                            />
                            <path
                                id="smile"
                                d="M272.395 285.749C272.395 285.749 276.01 288.916 282.748 289.222C288.242 289.471 291.897 286.134 291.897 286.134"
                                stroke="#30343F"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </g>
                    </g>
                </g>
                <g id="Meja">
                    <rect
                        id="Kayu"
                        x="9"
                        y="450"
                        width="777"
                        height="12"
                        fill="#2B5C99"
                    />
                    <g id="Laptop">
                        <path
                            id="Rectangle 6"
                            d="M328.284 341.508C328.509 340.621 329.307 340 330.222 340H491.365C492.689 340 493.647 341.263 493.291 342.538L465.075 443.538C464.834 444.402 464.046 445 463.149 445H304.571C303.267 445 302.312 443.772 302.633 442.508L328.284 341.508Z"
                            fill="#729FE3"
                        />
                        <path
                            id="Rectangle 5"
                            d="M332.892 341.405C333.12 340.521 333.917 339.904 334.829 339.904H495.675C497.003 339.904 497.962 341.173 497.599 342.45L469.453 441.653C469.208 442.513 468.423 443.107 467.529 443.107H309.241C307.934 443.107 306.978 441.873 307.304 440.607L332.892 341.405Z"
                            fill="#A8CEFF"
                        />
                        <rect
                            id="Rectangle 7"
                            x="240.315"
                            y="443.107"
                            width="229.995"
                            height="7.37162"
                            rx="2"
                            fill="#71A3DE"
                        />
                        <path
                            id="Vector 2_2"
                            d="M401.176 389.624L390.88 391.708L395.183 387.825L422.649 367.134L403.323 385.985L418.198 381.018L402.452 393.805L414.748 391.137L407.288 399.03L379 421.892L390.372 410.152L400.037 399.838L382.664 402.911L401.176 389.624Z"
                            fill="white"
                        />
                    </g>
                    <g id="vas">
                        <path
                            id="bunga-3"
                            d="M667.2 383.086C667.2 383.086 670.966 395.755 672.336 401.576H675.418C675.418 401.576 675.461 399.697 675.418 398.495C675.208 392.754 673.867 389.649 672.336 384.113C670.983 379.222 670.966 375.895 668.227 371.786C665.487 367.677 660.009 358.431 660.009 358.431L650.763 339.941L642.545 314.259L633.3 275.223C633.3 275.223 632.491 263.77 628.164 264.951C625.043 265.802 624.739 269.06 624.055 272.141C623.37 275.223 622 282.414 622 282.414V306.041C622 306.041 623.199 320.156 626.109 328.641C629.371 338.15 632.475 343.145 638.436 351.241C643.653 358.325 649.736 362.883 653.845 367.677C657.954 372.471 667.2 383.086 667.2 383.086Z"
                            fill="#71B46D"
                        />
                        <path
                            id="bunga-1"
                            d="M663.09 380.004C663.691 388.809 663.09 402.604 663.09 402.604H667.2V396.44C667.2 396.44 668.571 384.547 668.227 376.922C667.861 368.817 665.91 364.454 665.145 356.377C663.822 342.398 661.142 333.881 665.145 320.423C666.122 317.137 666.894 315.363 668.227 312.205C674.628 297.032 684.351 291.937 691.854 277.278C699.256 262.816 701.904 253.9 706.235 238.242C709.298 227.17 715.481 215.642 712.399 209.478C711.861 208.402 709.317 209.478 709.317 209.478C709.317 209.478 700.192 217.255 694.936 222.833C678.514 240.257 663.09 275.223 663.09 275.223C663.09 275.223 654.722 292.856 652.818 305.014C651.142 315.715 651.975 321.951 652.818 332.75C654.288 351.577 661.806 361.163 663.09 380.004Z"
                            fill="#85CA82"
                        />
                        <path
                            id="bunga-2"
                            d="M651.791 372.813C651.561 384.493 660.009 401.576 660.009 401.576H655.9C655.9 401.576 650.092 389.419 648.709 381.031C647.403 373.115 647.67 368.442 648.709 360.486C649.729 352.67 650.696 348.193 653.845 340.968C662.591 320.903 684.663 302.959 695.963 303.987C702.766 304.605 707.44 308.718 709.317 315.286C713.426 329.668 715.481 340.968 719.59 360.486C721.243 368.337 723.297 385.202 721.644 385.14C719.991 385.078 718.32 385.569 717.535 384.113C703.154 357.404 693.361 329.8 685.69 332.75C672.336 337.886 659.001 344.068 653.845 359.459C652.169 364.462 651.894 367.538 651.791 372.813Z"
                            fill="#91D290"
                        />
                        <path
                            id="vas"
                            d="M682.608 401.576H648.709L655.9 449.858H676.445L682.608 401.576Z"
                            fill="#7BCCE7"
                            stroke="#7BCCE7"
                        />
                    </g>
                    <g id="asap">
                        <path
                            id="Vector 23"
                            d="M85.739 404.402C85.9549 409.199 91.551 415.269 91.551 415.269C91.551 415.269 84.8315 412.351 81.8644 408.237C77.1754 401.736 76.5688 395.357 80.5729 388.421C83.1561 383.946 86.3728 381.946 87.6763 376.914C88.9354 372.054 86.3848 364.13 86.3848 364.13C86.3848 364.13 98.8517 370.522 98.0087 380.75C97.5864 385.873 91.551 392.256 91.551 392.256C91.551 392.256 85.503 399.159 85.739 404.402Z"
                            fill="#A1C9FD"
                        />
                        <path
                            id="Vector 24"
                            d="M95.753 404.942C95.7166 406.655 97.866 408.981 97.866 408.981C97.866 408.981 95.1708 407.743 94.0546 406.191C92.2905 403.739 92.2071 401.451 94.04 399.102C95.2224 397.587 96.6014 396.971 97.2706 395.219C97.917 393.527 97.0722 390.631 97.0722 390.631C97.0722 390.631 102.047 393.276 101.432 396.89C101.124 398.701 98.4677 400.793 98.4677 400.793C98.4677 400.793 95.7927 403.069 95.753 404.942Z"
                            fill="#A1C9FD"
                        />
                        <path
                            id="Vector 25"
                            d="M72.0285 396.156C72.167 399.514 75.7575 403.763 75.7575 403.763C75.7575 403.763 71.4462 401.72 69.5425 398.84C66.5339 394.289 66.1447 389.824 68.7138 384.969C70.3712 381.837 72.4351 380.437 73.2715 376.914C74.0794 373.512 72.4428 367.965 72.4428 367.965C72.4428 367.965 80.4418 372.44 79.9009 379.599C79.63 383.186 75.7575 387.654 75.7575 387.654C75.7575 387.654 71.877 392.485 72.0285 396.156Z"
                            fill="#A1C9FD"
                        />
                    </g>
                    <g id="cup">
                        <path
                            id="Vector 21"
                            d="M115.583 419.759H59.5391C56.218 421.004 57.2793 430.456 60.3694 436.78C63.7416 443.682 72.8236 450.479 72.8236 450.479H103.129C103.129 450.479 111.688 442.57 115.583 435.949C119.735 428.892 118.904 421.419 115.583 419.759Z"
                            fill="#75A0E0"
                        />
                        <path
                            id="Vector 22"
                            d="M114.781 427.448C114.781 427.448 121.862 425.091 124.781 427.448C128.064 430.101 126.785 434.118 124.781 437.671C122.64 441.466 119.408 443.014 114.781 443.728C111.717 444.202 106.865 442.971 106.865 442.971"
                            stroke="#75A0E0"
                            stroke-width="3"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { TimelineMax } from "gsap";
export default {
    mounted() {
        var interfaceTl = new TimelineMax({ repeat: -1, delay: 9.6 });
        interfaceTl
            .from("#interface-1", 1, { opacity: 0, y: 200 })
            .from("#interface-2", 1, { opacity: 0, y: 200 })
            .from("#interface-3", 1, { opacity: 0, y: 200 })
            .from("#check-1", 1, { opacity: 0 })
            .from("#check-2", 1, { opacity: 0 })
            .from("#check-3", 1, { opacity: 0 })
            .to("#check-1", 0.2, { opacity: 0 })
            .to("#interface-1", 1, { opacity: 0, x: 200 })
            .to("#check-2", 0.2, { opacity: 0 })
            .to("#interface-2", 1, { opacity: 0, x: 200 })
            .to("#check-3", 0.2, { opacity: 0 })
            .to("#interface-3", 1, { opacity: 0, x: 200 });

        var coffeeTL = new TimelineMax({ repeat: -1 });
        coffeeTL.from("#asap", 3, { y: -10 }).to("#asap", 3, { y: -10 });

        var eyeTL = new TimelineMax({ repeat: -1 });
        eyeTL
            .set("#ushape", { opacity: 0 })
            .set("#stripe", { opacity: 0 })
            .to("#round", 0.1, { opacity: 0 }, "+=2.9")
            .from("#ushape", 0.1, { opacity: 1 })
            .to("#ushape", 0.1, { opacity: 0 }, "+=2.8")
            .from("#round", 0.1, { opacity: 1 })
            .to("#round", 0.1, { opacity: 0 }, "+=3.4");

        var mouthTL = new TimelineMax({ repeat: -1 });
        mouthTL
            .set("#laugh", { opacity: 0 })
            .set("#smile", { opacity: 0 })
            .set("#round_2", { opacity: 0 })
            .to("#straight", 0.1, { opacity: 0 }, "+=2.9")
            .from("#laugh", 0.1, { opacity: 1 })
            .to("#laugh", 0.1, { opacity: 0 }, "+=2.8")
            .from("#smile", 0.1, { opacity: 1 })
            .to("#smile", 0.1, { opacity: 0 }, "+=3.4");

        var handTL = new TimelineMax({ repeat: -1 });
        handTL
            .from("#hand", 1, { rotation: 3, transformOrigin: "left 50%" })
            .to("#hand", 1, { rotation: 3, transformOrigin: "left 50%" });

        var bunga1TL = new TimelineMax({ repeat: -1 });
        bunga1TL
            .from("#bunga-1", 2, {
                rotation: 3,
                transformOrigin: "bottom 50%",
                delay: 1,
            })
            .to("#bunga-1", 2, { rotation: 3, transformOrigin: "bottom 50%" });

        var bunga2TL = new TimelineMax({ repeat: -1 });
        bunga2TL
            .from("#bunga-2", 2, { rotation: 3, transformOrigin: "bottom 50%" })
            .to("#bunga-2", 2, { rotation: 3, transformOrigin: "bottom 50%" });

        var bunga3TL = new TimelineMax({ repeat: -1 });
        bunga3TL
            .from("#bunga-3", 2, {
                rotation: 5,
                transformOrigin: "bottom 50%",
                delay: 2,
            })
            .to("#bunga-3", 2, { rotation: 5, transformOrigin: "bottom 50%" });
    },
};
</script>

<style></style>
