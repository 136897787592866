<template>
    <div class="greeting">
        <h1 class="title">
            <span class="word">H</span>
            <span class="word">i</span>
            <span class="word">,</span>
        </h1>
        <h1 class="title">
            <span class="word">I</span>
            <span class="word">'</span>
            <span class="word">m</span>
            <span class="word">&nbsp;</span>
            <span class="word">Z</span>
            <span class="word">u</span>
            <span class="word">d</span>
            <span class="word">i</span>
            <span class="word">t</span>
            <span class="word">h</span>
        </h1>
        <h1 class="title">
            <span class="word">a</span>
            <span class="word">&nbsp;</span>
            <span class="word yellow">f</span>
            <span class="word yellow">u</span>
            <span class="word yellow">l</span>
            <span class="word yellow">l</span>
            <span class="word yellow">-</span>
            <span class="word yellow">s</span>
            <span class="word yellow">t</span>
            <span class="word yellow">a</span>
            <span class="word yellow">c</span>
            <span class="word yellow">k</span>
        </h1>
        <h1 class="title">
            <span class="word">D</span>
            <span class="word">e</span>
            <span class="word">v</span>
            <span class="word">e</span>
            <span class="word">l</span>
            <span class="word">o</span>
            <span class="word">p</span>
            <span class="word">e</span>
            <span class="word">r</span>
        </h1>
        <h4 class="sub-title">
            <span class="word2">b</span>
            <span class="word2">a</span>
            <span class="word2">s</span>
            <span class="word2">e</span>
            <span class="word2">d</span>
            <span class="word2">&nbsp;</span>
            <span class="word2">i</span>
            <span class="word2">n</span>
            <span class="word2">&nbsp;</span>
            <span class="word2">J</span>
            <span class="word2">a</span>
            <span class="word2">k</span>
            <span class="word2">a</span>
            <span class="word2">r</span>
            <span class="word2">t</span>
            <span class="word2">a</span>
            <span class="word2">,</span>
        </h4>
        <h4 class="sub-title">
            <span class="word2">I</span>
            <span class="word2">n</span>
            <span class="word2">d</span>
            <span class="word2">o</span>
            <span class="word2">n</span>
            <span class="word2">e</span>
            <span class="word2">s</span>
            <span class="word2">i</span>
            <span class="word2">a</span>
        </h4>
    </div>
</template>

<script>
import { TweenMax, Back } from "gsap";

export default {
    mounted() {
        TweenMax.staggerFrom(
            ".word",
            1,
            { scale: 0, ease: Back.easeOut, delay: 3 },
            0.05
        );
        TweenMax.staggerFrom(
            ".word2",
            1,
            { opacity: 0, x: -100, ease: Back.easeOut, delay: 5 },
            0.05
        );
    }
};
</script>

<style scoped>
.greeting {
    padding-left: 5vh;
    z-index: 1;
}
.title {
    font-weight: 700;
    display: flex;
    font-size: 50px;
}
.sub-title {
    font-weight: 300;
    display: flex;
    font-size: 24px;
}
.yellow {
    font-weight: 900;
    color: #ffd600;
}

.reveal {
    animation: text-revealer 0.5s;
}

.word:hover {
    animation: rubberBand 1s;
    cursor: pointer;
    color: #ffd600;
}

.word.yellow:hover {
    color: #303030;
}

@keyframes clip-text {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes text-revealer {
    0% {
        color: transparent;
        background-color: #ffd600;
        transform: scaleX(0);
        transform-origin: -35% 50%;
    }

    40% {
        color: transparent;
        background-color: #ffd600;
        transform-origin: 0% 50%;
    }

    50% {
        color: transparent;
        background-color: #ffd600;
        transform-origin: 100% 50%;
        transform: scaleX(1);
    }

    89% {
        color: transparent;
        background-color: #ffd600;
        transform: scaleX(0);
        transform-origin: 100% 50%;
    }
    90% {
        transform-origin: 0% 50%;
    }
}
</style>
