import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import Porto from "./layouts/Porto.vue";
import home from "./views/Home.vue";
import about from "./views/About.vue";
import works from "./views/Works.vue";
import contact from "./views/Contact.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: configRoutes()
});

function configRoutes() {
    let appRoutes = [{
            path: "/home",
            name: "home",
            component: home
        },
        {
            path: "/about",
            name: "about",
            component: about
        },
        {
            path: "/works",
            name: "works",
            component: works
        },
        {
            path: "/contact",
            name: "contact",
            component: contact
        }
    ];

    

    let routes = [{
            path: "/",
            redirect: "/home",
            name: "Home",
            component: Porto,
            children: appRoutes
        },
    ];

    return routes;
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.isAuth)) {
        // console.log(store.getters['auth/authenticated'])
        let user = store.getters['auth/authenticated']
        if (!user) {
            next('/auth')
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
