<template>
    <div class="container-fluid">
        <div
            class="row align-items-center justify-content-around"
            style="min-height:100%;position:relative;"
        >
            <div class="col-md-6 order-1 order-md-2 p-3 text-center">
                <Head
                    class="head animate__animated animate__fadeIn animate__delay-2s"
                ></Head>
            </div>
            <div class="col-md-6 order-2 order-md-1 m-auto pb-5 px-5">
                <h1 class="title mb-3">
                    <span class="word">A</span>
                    <span class="word">b</span>
                    <span class="word">o</span>
                    <span class="word">u</span>
                    <span class="word">t</span>
                    <span class="word">&nbsp;</span>
                    <span class="word">m</span>
                    <span class="word">e</span>
                    <span class="word yellow">.</span>
                </h1>
                <p class="animate__animated animate__fadeIn animate__delay-4s">
                    Hello! my name is Zudith. I currently work as an ICT Analyst
                    @
                    <a href="https://www.gmf-aeroasia.co.id" target="_blank"
                        >GMF Aeroasia</a
                    >
                    with the current responsibility of designing, analyzing, and
                    evaluating the Integration of all ICT Pillar (System,
                    Infrastructure & Asset) based on ICT best practices and
                    proven business process to fulfill the business requirement.
                </p>
                <p class="animate__animated animate__fadeIn animate__delay-4s">
                    I've experienced many years working in Information
                    Technology especially in Software Development. I have
                    developed skills both in the backend and frontend of the
                    application, which makes me capable to build apps
                    completely. From gathering user requirements, creating a
                    business case, designing mockup and prototype, developing
                    full-stack, and deploying the apps to the server.
                </p>
                <p class="animate__animated animate__fadeIn animate__delay-4s">
                    I am a very logical person who so passionate and enjoying so
                    much programming and designing. Create something out of
                    nothing and be creative to solve the obstacle and find the
                    proper solution.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
import { TweenMax, Back } from "gsap";

export default {
    components: {
        Head
    },
    mounted() {
        TweenMax.staggerFrom(
            ".word",
            1,
            { opacity: 0, y: -100, ease: Back.easeOut, delay: 3 },
            0.05
        );
    }
};
</script>

<style scoped>
p {
    font-size: 13px;
}
.animate__delay-6s {
    animation-delay: 6s;
}
.animate__delay-7s {
    animation-delay: 7s;
}
.title {
    font-weight: 700;
    display: flex;
    font-size: 50px;
}

.head {
    width: 100%;
}
.yellow {
    font-weight: 900;
    color: #ffd600;
}
.word:hover {
    animation: rubberBand 1s;
    cursor: pointer;
    color: #ffd600;
}
</style>
