<template>
    <div class="container-fluid">
        <div class="row align-items-center" style="position: relative">
            <div class="col-lg-6 py-5 text-center">
                <svg
                    width="90%"
                    viewBox="0 0 537 219"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="mailbox">
                        <path
                            id="Vector 44"
                            d="M506 215.5H32C30.1667 205 27.6 179.7 32 162.5C37.5 141 50.5 116.5 113 118.5C163 120.1 205.5 96.8333 220.5 85C217.667 83.3333 233 77 303 45.5C391.749 5.56318 449 53 485.5 96.5C514.072 130.551 512.833 188.333 506 215.5Z"
                            fill="#ED4853"
                        />
                        <g id="Group 15">
                            <path
                                id="Vector 39"
                                d="M131.201 93.9721C124.801 108.772 133.868 131.472 139.201 140.972L143.701 214.972H228.201C239.201 207.472 240.701 183.472 242.701 172.972C244.701 162.472 246.201 154.972 249.201 150.472C252.201 145.972 260.701 140.972 265.701 136.972C269.701 133.772 276.035 125.972 278.701 122.472C280.368 118.472 282.001 108.372 275.201 99.9721C266.701 89.4721 244.701 101.472 233.701 106.472C222.701 111.472 184.201 151.472 182.201 140.972C180.201 130.472 163.201 93.9721 158.701 89.4721C154.201 84.9721 139.201 75.4721 131.201 93.9721Z"
                                fill="#61BDC8"
                            />
                            <path
                                id="Vector 42"
                                d="M169 214.5C168.333 188.5 163.7 130.4 150.5 106"
                                stroke="white"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                            <path
                                id="Vector 43"
                                d="M199.5 214.5C202 191 217.9 137.9 261.5 113.5"
                                stroke="white"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                        </g>
                        <g id="Group 14">
                            <rect
                                id="Rectangle 20"
                                x="168"
                                y="69"
                                width="12"
                                height="146"
                                fill="#353E5D"
                            />
                            <path
                                id="Vector 33"
                                d="M233.5 70.5H118.5V20.5C118.5 12.1 129.5 10.3333 135 10.5C158.833 10.3333 209.3 10.1 220.5 10.5C231.7 10.9 233.833 17.3333 233.5 20.5V70.5Z"
                                fill="#F15755"
                            />
                            <path
                                id="Vector 36"
                                d="M202 66H118V71H202V66Z"
                                fill="#C6393E"
                            />
                            <path
                                id="Vector 34"
                                d="M234 71H202V22.5965C202 18.0643 205.052 9 217.262 9C229.471 9 233.508 18.0643 234 22.5965V71Z"
                                fill="#EE3B4E"
                            />
                            <path
                                id="Vector 35"
                                d="M237 66.5H204.5V22C204.5 17.8333 207.6 9.5 220 9.5C232.4 9.5 236.5 17.8333 237 22V66.5Z"
                                fill="#D2383A"
                            />
                            <g id="handle">
                                <circle
                                    id="Ellipse 9"
                                    cx="185"
                                    cy="36"
                                    r="7"
                                    fill="#F77843"
                                />
                                <path
                                    id="Vector 37"
                                    d="M172.5 12.5H180H181V34.5C181 36.3333 181 40 185 40C189 40 189 36.3333 189 34.5V4C189 1.6 187.5 0 185.5 0H172.5C171.833 1 170 3.3 170 6.5C170 9.7 171.833 12 172.5 12.5Z"
                                    fill="#FBB216"
                                />
                            </g>
                        </g>
                        <g id="Group 16">
                            <g id="Vector 38">
                                <path
                                    d="M109.5 174C116.7 187.2 114.5 207.167 112.5 215.5H162.5C162.667 209.333 164.239 194.356 162.5 184.5C159.5 167.5 157 158 146.5 145C136 132 119.5 122 112.5 122C105.5 122 90.9999 120 85.4999 134C79.9999 148 100.5 157.5 109.5 174Z"
                                    fill="#D7E7DD"
                                />
                                <path
                                    d="M206 153.5C198.4 178.7 198.167 205.333 199 215.5H168.5C168.5 215.5 168.5 184.5 171 167C173.5 149.5 182.1 128 184.5 122C187.5 114.5 203 86 210.5 81C218 76 228 75.5 231.5 81C235 86.5 231 99.5 225.5 107C220 114.5 215.5 122 206 153.5Z"
                                    fill="#D7E7DD"
                                />
                            </g>
                            <path
                                id="Vector 40"
                                d="M138 214C139.5 193 131.9 146.8 109.5 138"
                                stroke="white"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                            <path
                                id="Vector 41"
                                d="M180 215.5C180.333 190.833 188.4 131.3 218 90.5"
                                stroke="white"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                        </g>
                        <path
                            id="path"
                            d="M227.5 46.5C249 43.5 299.9 48 337.5 90C384.5 142.5 373.5 195.5 349.5 196.5C325.5 197.5 318 132.5 382.5 156.5C414 168.221 484 139.167 497.5 134.5"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-dasharray="5 5"
                        />
                        <g id="amplop">
                            <path
                                id="Vector 45"
                                d="M210.756 57.9192L210.384 21.7099L265.876 23.9217L264.036 59.8085L210.756 57.9192Z"
                                fill="#EDF1FD"
                            />
                            <path
                                id="Vector 46"
                                d="M264.608 24.8668L238.155 42.0268L211.961 23.2955"
                                stroke="#D5E0FE"
                                stroke-width="2"
                            />
                        </g>
                        <rect
                            id="Rectangle 21"
                            y="214"
                            width="537"
                            height="5"
                            fill="#303030"
                        />
                    </g>
                </svg>
            </div>
            <div class="col-lg-6 px-5 pb-5">
                <h1 class="title">
                    <span class="word">C</span>
                    <span class="word">o</span>
                    <span class="word">n</span>
                    <span class="word">t</span>
                    <span class="word">a</span>
                    <span class="word">c</span>
                    <span class="word">t</span>
                    <span class="word">&nbsp;</span>
                    <span class="word">m</span>
                    <span class="word">e</span>
                    <span class="word yellow">.</span>
                </h1>
                <p>
                    Occationally looking for freelance opportunities. If you
                    have exciting project and interested for using my service,
                    dont hesitate to contact me using this form or send me
                    message in my social media account below.
                </p>
                <div v-if="message" class="alert alert-success" role="alert">
                    {{ message }}
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="inputName">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="inputName"
                            v-model="form.name"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputEmail">Email</label>
                        <input
                            type="email"
                            class="form-control"
                            id="inputEmail"
                            v-model="form.email"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="inputSubject">Subject</label>
                    <input
                        type="text"
                        class="form-control"
                        id="inputSubject"
                        v-model="form.subject"
                    />
                </div>
                <div class="form-group">
                    <label for="inputMessage">Message</label>
                    <textarea
                        class="form-control"
                        id="inputMessage"
                        rows="3"
                        v-model="form.message"
                    ></textarea>
                </div>
                <div class="form-row mt-2">
                    <div class="form-group col-md-4 offset-md-8">
                        <button
                            @click="onSubmit"
                            class="btn btn-primary btn-block"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TweenMax, Back, gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import axios from "axios";

gsap.registerPlugin(MotionPathPlugin);

export default {
    data() {
        return {
            form: {
                name: null,
                email: null,
                subject: null,
                message: null,
            },
        };
    },
    methods: {
        onSubmit() {
            let formData = new FormData();
            formData.append("name", this.form.name);
            formData.append("email", this.form.email);
            formData.append("subject", this.form.subject);
            formData.append("message", this.form.message);

            axios
                .post("api/message", formData, {
                    headers: { "content-type": "multipart/formdata" },
                })
                .then((response) => {
                    if (response) {
                        this.form.name = "";
                        this.form.email = "";
                        this.form.subject = "";
                        this.form.message = "";

                        this.message = "Message sent!";
                    }
                });
        },
    },
    mounted() {
        TweenMax.staggerFrom(
            ".word",
            1,
            { opacity: 0, y: -100, ease: Back.easeOut, delay: 3 },
            0.05
        );

        gsap.to("#handle", {
            duration: 3,
            repeat: -1,
            rotation: -90,
            transformOrigin: "70% 90%",
            delay: 2,
            yoyo: true,
        });

        gsap.to("#amplop", {
            duration: 5,
            repeat: -1,
            delay: 2,
            repeatDelay: 3,
            ease: "power1.inOut",
            yoyo: true,
            motionPath: {
                path: "#path",
                align: "#path",
                reversed: true,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
            },
        });
    },
};
</script>

<style>
.title {
    font-weight: 700;
    display: flex;
    font-size: 50px;
}
.yellow {
    font-weight: 900;
    color: #ffd600;
}
.word:hover {
    animation: rubberBand 1s;
    cursor: pointer;
    color: #ffd600;
}
</style>
