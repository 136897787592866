<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-light bg-transparent">
            <a
                class="navbar-brand"
                href="#"
                style="padding-top: 1rem; padding-left: 1rem"
                ><Logo></Logo
            ></a>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" active-class="active" to="/home">
                        <router-link
                            class="nav-link pr-5 pl-5 text-right text-md-center"
                            :to="{ name: 'home' }"
                            >HOME</router-link
                        >
                    </li>
                    <li class="nav-item" active-class="active" to="/about">
                        <router-link
                            class="nav-link pr-5 pl-5 text-right text-md-center"
                            :to="{ name: 'about' }"
                            >ABOUT</router-link
                        >
                    </li>
                </ul>
            </div>
        </nav>
        <div>
            <transition v-on:enter="enter" v-on:leave="leave" appear>
                <router-view class="page"></router-view>
            </transition>
        </div>
        <div class="loading container-fluid">
            <div
                class="row align-items-center justify-content-center"
                style="min-height: 100%"
            >
                <div class="col-auto">
                    <svg
                        class="transition-logo animate__animated animate__pulse animate__infinite"
                        width="100"
                        height="100"
                        viewBox="0 0 65 91"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                    >
                        <path
                            d="M32.5056 38.4352L17.3717 41.4063L23.6875 35.822L64.0095 6.0801L35.6516 33.1962L57.5098 26.0837L34.3952 44.4683L52.469 40.6604L41.5245 52.017L1.08926e-06 84.8855L16.6852 67.9968L30.8646 53.1578L5.32635 57.5312L32.5056 38.4352Z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <div class="footer container-fluid">
            <div class="row align-items-center">
                <div class="col-1 col-md-1 text-center">
                    <img src="@/assets/profile.png" class="photo" />
                </div>
                <div class="col-6 col-md-3">
                    <h5
                        class="animate__animated animate__fadeInUp animate__delay-3s"
                        style="font-size: 14px; font-weight: 100"
                    >
                        ZUDITH M IQBAL
                    </h5>
                    <h6
                        class="animate__animated animate__fadeInUp animate__delay-4s"
                        style="font-size: 12px; font-weight: 100"
                    >
                        <strong style="font-weight: 900">FULL STACK</strong>
                        DEVELOPER
                    </h6>
                </div>
                <div class="col-3 col-md-8 pe-5 pb-1" style="text-align: right">
                    <a
                        href="https://facebook.com/zudith"
                        target="_blank"
                        class="px-1"
                    >
                        <img
                            src="@/assets/facebook.png"
                            class="my-1"
                            width="24"
                            height="24"
                        />
                    </a>
                    <a
                        href="https://instagram.com/zudith"
                        target="_blank"
                        class="px-1"
                    >
                        <img
                            src="@/assets/instagram.png"
                            class="my-1"
                            width="24"
                            height="24"
                            target="_blank"
                        />
                    </a>
                    <a
                        href="https://www.linkedin.com/in/zudith/"
                        target="_blank"
                        class="px-1"
                    >
                        <img
                            src="@/assets/linkedin.png"
                            class="my-1"
                            width="24"
                            height="24"
                        />
                    </a>
                    <a
                        href="https://twitter.com/zudith"
                        target="_blank"
                        class="px-1"
                    >
                        <img
                            src="@/assets/twitter.png"
                            class="my-1"
                            width="24"
                            height="24"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import { Power1, TimelineMax } from "gsap";
export default {
    components: {
        Logo,
    },
    methods: {
        enter(el, done) {
            const tl = new TimelineMax();
            tl.set(el, { autoAlpha: 0 })
                .set(".transition-logo", { opacity: 0 })
                .to(".loading", 0, {
                    autoAlpha: 1,
                })
                .from(".loading", 0.5, {
                    left: "-200%",
                    ease: Power1.easeOut,
                })
                .to(".transition-logo", 0.3, { opacity: 1 })
                .to(".transition-logo", 0.3, { opacity: 0, delay: 1 })

                .to(el, 0, { autoAlpha: 1 })
                .to(".loading", 0.5, {
                    left: "200%",
                    ease: Power1.easeOut,
                })
                .to(".loading", 0, {
                    autoAlpha: 0,
                    left: 0,
                    onComplete: done,
                });
        },
        leave(el, done) {
            const tl = new TimelineMax();
            tl.to(el, 0, {
                autoAlpha: 0,
                onComplete: done,
            });
        },
    },
};
</script>

<style>
@import "~animate.css/animate.css";
</style>

<style scoped>
.photo {
    text-align: right;
    width: 50px;
    height: 50px;
}
.router-link-exact-active {
    font-weight: bolder;
    color: #000 !important;
}
.nav-item:hover,
.nav-item:focus {
    border-bottom: solid 2px #ffd600 !important;
}

.footer {
    height: 10vh;
    z-index: 5;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: #010101;
    z-index: 11;
}

.page {
    width: 100vw;
}

.navbar {
    z-index: 10;
}
.navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-width: 0;
    border-top: 4px solid;
    border-image-source: linear-gradient(
        to right,
        rgb(87, 90, 123),
        rgb(87, 90, 123) 20%,
        rgb(249, 101, 91) 20%,
        rgb(249, 101, 91) 40%,
        rgb(245, 192, 112) 40%,
        rgb(245, 192, 112) 60%,
        rgb(102, 88, 234) 60%,
        rgb(102, 88, 234) 80%,
        rgb(255, 204, 204) 80%
    );
    border-image-slice: 1;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
}

@keyframes borderColors {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    33% {
        border-top-color: #ffd600;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    66% {
        border-top-color: #ffd600;
        border-right-color: #ffd600;
        border-bottom-color: transparent;
        border-left-color: #ffd600;
    }
    100% {
        border-top-color: #ffd600;
        border-right-color: #ffd600;
        border-bottom-color: #ffd600;
        border-left-color: #ffd600;
    }
}
</style>
